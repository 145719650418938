import * as React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { HiOutlineExternalLink } from "react-icons/hi"

const projects = [
  {
    title: `UI Foundations Kit`,
    description: `Beautiful pre-built MUI components.`,
    url: `https://kit.uifoundations.com/`,
    gradient: [`#ff7ddc`, `#ea3376`],
  },
  {
    title: `UI Foundations`,
    description: `A premium course that teaches good design.`,
    url: `https://uifoundations.com/`,
    gradient: [`#D946ef`, `#9333EA`],
  },
  {
    title: `Sol Journal`,
    description: `Simple, open-source, personal journaling app.`,
    url: `https://soljournal.netlify.app/`,
    gradient: [`#8B5CF6`, `#4F46E5`],
  },
  {
    title: `Audio Vault`,
    description: `Curated music with deeper meaning.`,
    url: `https://www.audio-vault.com/`,
    gradient: [`#3B82F6`, `#0284C7`],
  },
  {
    title: `Synthetic`,
    description: `Spotify powered music discovery.`,
    url: `https://synthetic.netlify.app/`,
    gradient: [`#7DD3FC`, `#22D3EE`],
  },
  {
    title: `Theme UI Gallery`,
    description: `Copy and paste-able Theme UI components.`,
    url: `https://theme-ui-gallery.netlify.app/`,
    gradient: [`#2DD4BF`, `#10B981`],
  },
  {
    title: `Scroll Solace`,
    description: `Chrome extension, hides social media feeds.`,
    url: `https://chrome.google.com/webstore/detail/scroll-solace-hide-social/pkkpjcbjoplobnnihelcjnigckibeaog/related?hl=en`,
    gradient: [`#4ADE80`, `#84CC16`],
  },
  {
    title: `Card Surge`,
    description: `UI playground for CSS cards.`,
    url: `https://card.surge.sh/`,
    gradient: [`#FACC15`, `#F59E0B`],
  },
]

const ProjectCard = ({ title, description, url, gradient }) => {
  return (
    <li>
      <Link to={url}>
        <div className="relative group hover-card py-5 sm:py-8 px-12 sm:px-24 rounded-xl">
          <div className="absolute top-0 bottom-0 left-0 h-full flex items-center justify-center w-12 sm:w-24">
            <div
              className={`h-5 sm:h-8 w-5 sm:w-8 rounded-full`}
              style={{
                background: `linear-gradient(to right bottom, ${gradient[0]},${gradient[1]})`,
              }}
            />
          </div>
          <div className="text-lg sm:text-xl">{title}</div>
          <div className="text-sm sm:text-md text-zinc-500 font-normal">
            {description}
          </div>
          <div className="absolute top-0 bottom-0 right-0">
            <HiOutlineExternalLink
              size={24}
              className="text-zinc-300 h-full mr-4 sm:mr-6 group-hover:text-zinc-400 transition-colors duration-200"
            />
          </div>
        </div>
      </Link>
    </li>
  )
}

export default function Projects() {
  return (
    <main>
      <Helmet>
        <title>Projects</title>
      </Helmet>
      <section className="container">
        <div className="pt-6 pb-8 space-y-2 md:space-y-5">
          <h1>Projects</h1>
          <p className="text-lg leading-6 text-zinc-500">
            Things I've built with a mix of code and design.
          </p>
        </div>
        <ul
          className="grid gap-5 sm:gap-8"
          style={{
            gridTemplateColumns: `1fr`,
          }}
        >
          {projects.map((project) => (
            <ProjectCard
              title={project.title}
              description={project.description}
              url={project.url}
              gradient={project.gradient}
            />
          ))}
        </ul>
        <div className="illustration">
          <StaticImage
            alt="Hand building blocks illustration"
            src="../images/hand.png"
            layout="FIXED"
            width={160}
            placeholder="TRACED_SVG"
            formats={["auto", "webp", "avif"]}
          />
        </div>
      </section>
    </main>
  )
}
